import React from 'react';
import Button, { ServiceButton } from '../../../../components/Button';

import './index.scss';

const Services = ({ services, goToDashboard, onSelectService }) => {
  const renderServices = () => {
    const filteredServices = services?.filter((service) => service.is_active);
    if (!filteredServices.length)
      return (
        <span style={{ width: '200px', color: 'red' }}>
          Service not available for the selected category
        </span>
      );
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {filteredServices.map((service, index) => (
          <ServiceButton
            key={`service-button-${index}`}
            style={{ margin: '12px' }}
            onClick={() => {
              onSelectService({ selectedService: service, selectedServiceName: service.name });
            }}
          >
            <div className='d-flex flex-column service-item-wrap'>
              <span style={{ textTransform: 'uppercase' }}>{service.name}</span>
              <span>{service?.short_description}</span>
            </div>
          </ServiceButton>
        ))}
      </div>
    );
  };

  return (
    <div className='service-wrapper d-flex flex-column align-items-center'>
      <div className='service-heading'>What type of appointment are you going to schedule ?</div>
      {renderServices()}
      <Button style={{ width: '120px', marginTop: '16px' }} onClick={goToDashboard}>
        Back
      </Button>
    </div>
  );
};

export default Services;
