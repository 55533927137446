import React, { useEffect, useRef, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';

import Verification from './components/Verification';
import Profile from './components/Profile';
import Appointment from './components/Appointment';
import Insurance from './components/Insurance';
import ThankyouPage from './components/ThankyouPage';
import Loader from '../../components/Loader';
import Surveys from './components/Surveys';
import Address from './components/Address';
import Dashboard from './components/Dashboard';
import CallWaitingPage from './components/CallWaitingPage';
import UserType from './components/UserType';
import UserAgreement from './components/UserAgreement';
import Services from './components/Services';

import 'react-toastify/dist/ReactToastify.css';
import { API_URLS } from '../../utils/constants';
import { getItemFromLocalStorage, getToken, sortByKey } from '../../utils/helpers';
import axios from '../../utils/axiosWrapper';

import { useAppContext } from '../../utils/appContext';
import './index.scss';

const BookAppointment = () => {
  const { logoutState, stepNo, setStepNo } = useAppContext();
  const [isLoaderVisible, setLoaderVisibility] = useState(false);
  const showLoader = () => setLoaderVisibility(true);
  const hideLoader = () => setLoaderVisibility(false);
  const categories = useRef();
  const surveyConfigs = useRef();
  const address = useRef([]);
  const [services, setServices] = useState([]);
  const initialProfileState = {
    firstname: {
      value: '',
      dirty: false,
      error: false,
      type: 'text',
      name: 'firstname',
      placeholder: 'First Name',
    },
    lastname: {
      value: '',
      dirty: false,
      error: false,
      type: 'text',
      name: 'lastname',
      placeholder: 'Last Name',
    },
    birthdate: {
      value: '',
      dirty: false,
      error: false,
      type: 'text',
      name: 'birthdate',
      placeholder: 'Birthdate',
    },
    email: {
      value: '',
      dirty: false,
      error: false,
      type: 'email',
      name: 'email',
      placeholder: 'Email',
    },
  };
  // this state is used to store the profile info
  const [profileInfo, setProfileInfo] = useState(initialProfileState);
  const [serviceInfo, setServiceInfo] = useState({
    selectedValue: '',
  });
  const [addressInfo, setAddressInfo] = useState({});
  const [insuranceInfo, setInsuranceInfo] = useState({});
  const surveyScore = useRef(null);
  const checkUserLoggedInOrNot = async () => {
    showLoader();
    const token = getToken();
    if (!token) {
      hideLoader();
      return;
    }
    const res = await axios({
      method: 'GET',
      url: API_URLS.videoCallingStatus,
    });

    try {
      if (!res.isError) {
        if (res.session.token) {
          goToDashboard();
          // hideLoader();
        }
      } else {
        setStepNo(1);
        hideLoader();
      }
    } catch (err) {
      console.log('err', err);
      setStepNo(1);
      hideLoader();
    }
  };

  const handleProfileInfoChange = (data) => {
    setProfileInfo({ ...profileInfo, ...data });
  };

  const fetchServiceInfo = async () =>
    axios({
      method: 'GET',
      url: API_URLS.dashboardInfo,
    });

  const fetchSurveyConfigs = async (selectedService) =>
    axios({
      method: 'GET',
      url: `${API_URLS.surveyList}?service=${selectedService.id}`,
      ignoreToken: true,
    });

  const goToDashboard = async () => {
    showLoader();
    //fetch service info
    const res = await fetchServiceInfo();
    if (!res.isError) {
      categories.current = res?.data?.pref_category;
    }
    hideLoader();
    setStepNo(5);
  };

  const goToSurveyOrAddressStep = ({ selectedService }) => {
    console.log('serviceInfo', selectedService);
    if (!selectedService.survey_enabled) {
      // if survey disabled
      fetchAddress();
      return;
    }
    showLoader();

    setTimeout(async () => {
      //fetch service info

      const res = await fetchSurveyConfigs(selectedService);
      if (!res.isError) {
        surveyConfigs.current = res.results;
        moveNext();
      }
      hideLoader();
    }, 700);
  };
  const moveNext = () => setStepNo(stepNo + 1);
  const afterSuccessfulVerification = ({ profileData }) => {
    let { isnew_user: isNewUser } = profileData;
    console.log('profileData', profileData);
    if (isNewUser) {
      moveNext();
    } else {
      if (profileData?.vp_terms_accepted) {
        goToDashboard();
      } else {
        setStepNo(4);
      }
    }
  };
  const goToAppointmentStep = () => setStepNo(10);
  const fetchAddress = async () => {
    const res = await axios({
      method: 'GET',
      url: API_URLS.addressApi,
    });
    if (!res.isError) {
      address.current = res.data;
    }
    setStepNo(8);
    hideLoader();
  };

  const fetchInsurances = async () => {
    showLoader();
    const res = await axios({
      method: 'GET',
      url: `${API_URLS.insuranceApi}?account=${getItemFromLocalStorage('accountId')}`,
    });
    if (!res.isError) {
      const data = res.results;
      const filteredInsurances = data?.filter((insurance) => !insurance.deleted) || []; // filter out insurances which are not deleted
      if (filteredInsurances.length > 0) {
        setInsuranceInfo(filteredInsurances[filteredInsurances.length - 1]);
        goToAppointmentStep();
      } else {
        moveNext();
      }
    }
    hideLoader();
  };

  const afterSurveySubmission = (surveryResData) => {
    console.log('surveryResData', surveryResData);
    surveyScore.current = surveryResData;
    fetchAddress();
  };
  const updateProfile = async ({ gender, ethnicity, race }) => {
    showLoader();
    const res = await axios({
      method: 'PUT',
      url: API_URLS.profileUrl,
      data: {
        email: profileInfo['email'].value,
        first_name: profileInfo['firstname'].value,
        last_name: profileInfo['lastname'].value,
        birthdate: profileInfo['birthdate'].formattedDate,
        name: `${profileInfo['firstname'].value} ${profileInfo['lastname'].value}`,
        gender,
        ethnicity,
        race,
      },
    });
    console.log('res', res);
    if (!res.isError) {
      moveNext();
      hideLoader();
    } else {
      hideLoader();
      toast.error('Patient exists with the same email');
    }
  };
  const acceptUserAgreement = async () => {
    const res = await axios({
      method: 'PUT',
      url: API_URLS.profileUrl,
      data: {
        vp_terms_accepted: true,
      },
    });
    console.log('res', res);
    if (!res.isError) {
      goToDashboard();
    }
  };

  const submitAppointmentAndMoveToLastpage = async ({ appointmentTime, source = '' }) => {
    showLoader();
    const res = await axios({
      url: API_URLS.bookAppointment,
      method: 'POST',
      data: {
        service: serviceInfo.id,
        serviceid: serviceInfo.id,
        service_name: serviceInfo.name,
        plan_timestamp: appointmentTime,
        address: addressInfo.id,
        insurance: insuranceInfo.id,
        type: source === 'home-visit' ? '2' : '1',
        status: '1',
        surveyscore: surveyScore?.current?.id,
        call_type: source === 'home-visit' ? '0' : source === 'onDemandCall' ? '1' : '2',
      },
    });
    if (!res.isError) {
      if (source === 'home-visit') {
        setStepNo(11);
      } else {
        setStepNo(12);
      }
      hideLoader();
    } else {
      const message = res?.errorResponse?.data?.status?.message || 'Something went wrong';
      hideLoader();
      toast.error(message);
    }
  };
  const renderSteps = () => {
    switch (stepNo) {
      case 1:
        return <UserType goToStep2={() => setStepNo(2)} />;
      case 2:
        return (
          <Verification
            showLoader={showLoader}
            hideLoader={hideLoader}
            onSuccess={afterSuccessfulVerification}
          />
        );
      case 3:
        return (
          <Profile
            profileInfo={profileInfo}
            handleProfileInfoChange={handleProfileInfoChange}
            updateProfile={updateProfile}
          />
        );

      case 4: {
        return <UserAgreement onSubmit={acceptUserAgreement} />;
      }
      case 5: {
        return (
          <Dashboard
            categories={categories.current}
            setServices={(value) => {
              setServices(value);
              setTimeout(() => setStepNo(6), 200);
            }}
          />
        );
      }
      case 6:
        return (
          <Services
            goToDashboard={() => setStepNo(5)}
            services={services}
            onSelectService={({ selectedService, selectedServiceName }) => {
              setServiceInfo({ ...selectedService, selectedValue: selectedServiceName });
              goToSurveyOrAddressStep({ selectedService });
            }}
          />
        );
      case 7:
        return (
          <Surveys
            goToDashboard={() => setStepNo(5)}
            showLoader={showLoader}
            hideLoader={hideLoader}
            configs={sortByKey(surveyConfigs.current, 'display_order').map((surveyConfig) => ({
              id: surveyConfig.id,
              question: surveyConfig.question,
              type: surveyConfig.question_type,
              values: surveyConfig.question_values.split(','),
              scores: surveyConfig.question_score?.split(','),
              surveyconfig: surveyConfig.surveyconfig,
            }))}
            afterSurveySubmission={afterSurveySubmission}
          />
        );
      case 8:
        return (
          <Address
            addresses={address.current.filter((add) => add.latitude && add.longitude)}
            showLoader={showLoader}
            hideLoader={hideLoader}
            selectedAddress={(addressInfo) => {
              setAddressInfo(addressInfo);
              fetchInsurances();
            }}
          />
        );
      case 9:
        return (
          <Insurance
            showLoader={showLoader}
            hideLoader={hideLoader}
            moveNext={goToAppointmentStep}
            selectedInsurance={(insuranceInfo) => {
              setInsuranceInfo(insuranceInfo);
              goToAppointmentStep();
            }}
            skipInsurance={goToAppointmentStep}
          />
        );
      case 10:
        return (
          <Appointment
            isOnDemandCallAllowed={serviceInfo.is_on_demand_call_allowed}
            isScheduledCallAllowed={serviceInfo.is_scheduled_call_allowed}
            isHomeVisitAllowed={serviceInfo.is_home_visit}
            submitAppointment={submitAppointmentAndMoveToLastpage}
            surveyScoreInfo={surveyScore.current}
            goToDashboard={() => setStepNo(5)}
          />
        );
      case 11:
        return <ThankyouPage goToDashboard={() => setStepNo(5)} />;
      case 12:
        return <CallWaitingPage goToDashboard={() => setStepNo(5)} />;
      default:
        return <UserType goToStep2={() => setStepNo(2)} />;
    }
  };

  useEffect(() => {
    // to reset token in local storage
    // setItemInLocalStorage('token', '');
    // setItemInLocalStorage('accountId', '');
    checkUserLoggedInOrNot();
    window.addEventListener(
      'beforeunload',
      (event) => {
        event.preventDefault();
        console.log('beforeunload called', event);
        // alert('please wait');
        return 'fdsf';
      },
      { capture: false },
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetStore = () => {
    setProfileInfo(initialProfileState);
  };

  useEffect(() => {
    if (logoutState) {
      setStepNo(1);
      resetStore();
    }
  }, [logoutState, setStepNo]);

  return (
    <>
      {isLoaderVisible && <Loader />}
      <div
        className='d-flex flex-column align-items-center book-appointent-container'
        style={stepNo >= 11 ? { height: '100%' } : {}}
      >
        {renderSteps()}
        <ToastContainer autoClose={3000} />
      </div>
    </>
  );
};

export default BookAppointment;
