import React, { useState } from 'react';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';

import Dropdown from '../../../../components/Dropdown';
import Arrow from '../../../../components/Arrow';
import Input from '../../../../components/Input';
import Text from '../../../../components/Text';
import { isFormValid, updateFormdata, validateForm } from '../../../../utils/helpers';
import './index.scss';

const Profile = ({ profileInfo, handleProfileInfoChange, updateProfile }) => {
  const errorMessages = {
    firstname: 'Please enter first name',
    lastname: 'Please enter last name',
    email: 'Please enter valid email',
  };
  const [gender, setGender] = useState('');
  const [ethnicity, setEthnicity] = useState('');
  const [race, setRace] = useState('');

  var d = new Date();
  const birthdateMax = d.setDate(d.getDate() - 31);
  const renderInputs = () =>
    Object.keys(profileInfo).map(
      (input) =>
        input !== 'birthdate' &&
        input !== 'email' && (
          <Input
            key={input}
            state={profileInfo[input]}
            placeholder={profileInfo[input].placeholder}
            onChange={handleProfileInfoChange}
            errorMessage={errorMessages[input]}
          />
        ),
    );
  const handleNext = () => {
    const validationOutput = validateForm({
      ...profileInfo,
      birthdate: {
        ...profileInfo.birthdate,
        value: profileInfo.birthdate.formattedDate,
      },
    });
    if (isFormValid(validationOutput)) {
      if (!gender) {
        toast.error('Please select Gender');
      } else
        updateProfile({
          gender,
          ethnicity,
          race,
        });
    } else {
      handleProfileInfoChange(updateFormdata(profileInfo, validationOutput));
    }
  };
  return (
    <div className='profile-form-wrapper'>
      <Text>Now, let’s create your profile</Text>
      <div className='d-flex profile-form'>
        {renderInputs()}
        <div className='email-wrapper'>
          <Input
            key={profileInfo['email']}
            state={profileInfo['email']}
            placeholder={profileInfo['email'].placeholder}
            onChange={handleProfileInfoChange}
            errorMessage={errorMessages['email']}
          />
        </div>

        <div
          className='d-flex gender-ethnicity-wrapper'
          style={{
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            width: '100%',
          }}
        >
          <div className='dd-wrapper'>
            <Dropdown
              placeholder='Choose your gender'
              options={[
                { value: '0', label: 'Male' },
                { value: '1', label: 'Female' },
                { value: '2', label: 'Others' },
                { value: '3', label: 'Decline to State' },
              ]}
              onChange={({ value }) => {
                setGender(value);
              }}
            />
          </div>
          <div className='dd-wrapper'>
            <Dropdown
              placeholder='Choose your ethnicity'
              options={[
                { value: '0', label: 'Hispanic (or) Latino' },
                { value: '1', label: 'Not Hispanic (or) Latino' },
              ]}
              onChange={({ value }) => {
                setEthnicity(value);
              }}
            />
          </div>
          <div className='picker-wrapper'>
            <DatePicker
              selected={profileInfo.birthdate.value}
              placeholderText={profileInfo.birthdate.placeholder}
              dateFormat='yyyy-MM-dd'
              onChange={(date) => {
                const dateObj = new Date(date);
                const formattedDate = `${dateObj.getFullYear()}-${
                  dateObj.getMonth() + 1
                }-${dateObj.getDate()}`;

                handleProfileInfoChange({
                  ...profileInfo,
                  birthdate: {
                    value: date,
                    error: false,
                    formattedDate: formattedDate,
                  },
                });
              }}
              showMonthDropdown
              showYearDropdown
              dropdownMode='select'
              maxDate={birthdateMax}
            />
            {profileInfo.birthdate.error && (
              <span style={{ color: 'red', fontSize: '14px' }}>Please select birthdate</span>
            )}
          </div>
          <div className='dd-wrapper'>
            <Dropdown
              placeholder='Choose your Race'
              options={[
                { value: '0', label: 'American Indian (or) Alaska Native' },
                { value: '1', label: 'Asian' },
                { value: '2', label: 'Native Hawaiian (or) Other Pacific Islander' },
                { value: '3', label: 'Black (or) African American' },
                { value: '4', label: 'White' },
                { value: '5', label: 'Other Race' },
                { value: '6', label: 'Unknown' },
                { value: '7', label: 'Unable to Report Due to Policy/ Law' },
              ]}
              onChange={({ value }) => {
                setRace(value);
              }}
            />
          </div>
        </div>
      </div>
      <Arrow onClick={handleNext} />
    </div>
  );
};
export default Profile;
