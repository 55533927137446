import BookAppointment from '../containers/BookAppointment';

const MOBILE_REGEXES = {
  in: /^[6-9]{1}[0-9]{9}$/,
  us: /^[0-9-]{8,11}$/,
};
const VALIDATION_REGEX = {
  otp: /^[0-9]{4}$/,
  countryCode: /^(\+?\d{1,3}|\d{1,4})$/,
  firstname: /^[a-zA-Z]{3,}$/,
  lastname: /^[a-zA-Z]{1,}$/,
  birthdate: /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/,
  email: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
  insuranceProvider: /(.*?)/,
  membershipId: /^[0-9a-zA-Z]{1,}$/,
  groupId: /^[0-9a-zA-Z]{1,}$/,
};
const API_URLS = {
  requestOtp: '/patient/otp/',
  verifyOtp: '/patient/otpverif/',
  profileUrl: '/patient/profile/',
  dashboardInfo: '/patient/dashboard/all',
  surveyList: '/surveys/v1',
  postSurveyAnswers: '/surveys/questionans',
  addressApi: '/patient/address/',
  insuranceApi: '/patient/insurance-patient-details/v2/',
  bookAppointment: '/patient/bookappointment/',
  videoCallingStatus: '/videocalling/api/get_callstatus',
};

const routes = [
  {
    path: '/',
    name: 'On-Demand care',
    key: 'On-Demand-care',
    page: <BookAppointment />,
    doNotRenderLink: false,
  },
];

const privacyPolicyURL = 'https://health-x.com/privacy-policy';
const endUserLicenseURL = 'https://health-x.com/user-agreements';
export { API_URLS, MOBILE_REGEXES, VALIDATION_REGEX, routes, privacyPolicyURL, endUserLicenseURL };
