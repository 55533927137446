import { useState } from 'react';

import leftArrow from '../../../../assets/left-arrow.svg';
import RadioGroup from '../../../../components/RadioGroup';
import Dropdown from '../../../../components/Dropdown';
import Arrow from '../../../../components/Arrow';
import Text from '../../../../components/Text';

import { API_URLS } from '../../../../utils/constants';
import axios from '../../../../utils/axiosWrapper';
import { getItemFromLocalStorage } from '../../../../utils/helpers';

import './index.scss';
import { toast } from 'react-toastify';

const FirstScreen = () => (
  <Text style={{ maxWidth: '400px' }}>
    The following questions serve as a preliminary screen to establish whether medication is the
    right course of treatment for you.
  </Text>
);
const QuestionScreen = ({
  question,
  type,
  id,
  surveyconfig,
  values,
  scores = [],
  selectedValue,
  onChooseAnswer,
}) => {
  const getScore = (value) => {
    const index = values.findIndex((val) => val === value);
    const scoreIndex = index >= 0 ? index : 0;
    return scores.length > 0 ? scores[scoreIndex] : '0'; // scores array can be empty
  };
  const renderQuestionTypes = () => {
    switch (type) {
      case '0': // dropdown
        return (
          <Dropdown
            key={question}
            options={values.map((value) => ({ value, label: value }))}
            onChange={({ value }) => {
              const score = getScore(value);
              onChooseAnswer(value, { name: question, id, surveyconfig, score });
            }}
          />
        );
      case '1': // radio / boolean
        return (
          <RadioGroup
            name={question}
            values={values}
            selectedValue={selectedValue}
            onChange={(value, { name }) => {
              const score = getScore(value);
              onChooseAnswer(value, { name, id, surveyconfig, score });
            }}
          />
        );
      case '2': // input
        return (
          <textarea
            className='survey-textarea'
            key={question}
            value={selectedValue}
            onChange={(e) => {
              const score = getScore(e.target.value);

              onChooseAnswer(e.target.value, {
                name: question,
                id,
                surveyconfig,
                score,
              });
            }}
          />
        );
      default:
        return;
    }
  };
  return (
    <div className='d-flex flex-column align-items-center large-text'>
      <div className='ff-museosans-sm m-4 text-center question-text'>{question}</div>
      {renderQuestionTypes()}
    </div>
  );
};
const Surveys = ({ showLoader, hideLoader, configs, afterSurveySubmission, goToDashboard }) => {
  const [questionNo, setQuestionNo] = useState(-1);

  const [selectedAns, setSelectedAns] = useState({});
  const totalQuestions = configs?.length;
  const onChooseAnswer = (ans, { name, id, surveyconfig, score }) => {
    setSelectedAns({
      ...selectedAns,
      [id]: {
        value: ans,
        score,
        surveyconfig,
      },
    });
  };
  const renderQuestions = () => {
    if (configs?.length === 0) return <div>Please configure surveys</div>;
    switch (questionNo) {
      case -1:
        return <FirstScreen />;
      default: {
        const props = { ...configs[questionNo] };
        return (
          <QuestionScreen
            {...props}
            selectedValue={selectedAns?.[props.id]?.value}
            onChooseAnswer={onChooseAnswer}
            questionNo={questionNo}
            totalQuestions={configs.length}
          />
        );
      }
    }
  };
  const formatSurveyPayload = () =>
    Object.keys(selectedAns).map((questionId) => {
      return {
        questionconfig: questionId,
        surveyconfig: selectedAns[questionId].surveyconfig.id,
        account: getItemFromLocalStorage('accountId'),
        question_ans: selectedAns[questionId].value,
        question_score: selectedAns[questionId].score,
      };
    });
  const validateSurveyBeforeSubmission = () => {
    return Object.keys(selectedAns).length === totalQuestions;
  };
  return (
    <div className='survey-wrapper d-flex flex-column align-items-center p-4'>
      {renderQuestions()}

      <div className='d-flex align-items-center'>
        {questionNo >= 1 && (
          <div
            className='m-t-24 d-flex align-items-center cursor-pointer'
            onClick={() => setQuestionNo(questionNo - 1)}
            style={{
              marginRight: '24px',
            }}
          >
            <img src={leftArrow} alt='left-arrow' style={{ width: 15, height: 15 }} />
            <span className='text-danger ff-museosans-med m-1'>Previous</span>
          </div>
        )}
        {questionNo <= totalQuestions && (
          <Arrow
            onClick={async () => {
              if (configs.length === 0) {
                goToDashboard();
                return;
              }
              if (questionNo === -1) {
                setQuestionNo(questionNo + 1);
              } else if (questionNo === totalQuestions - 1) {
                if (validateSurveyBeforeSubmission()) {
                  const currentQuestion = configs[questionNo];

                  if (!selectedAns[currentQuestion?.id]?.value) {
                    toast.error('Please provide input');
                    return;
                  }
                  showLoader();
                  const res = await axios({
                    method: 'POST',
                    url: API_URLS.postSurveyAnswers,
                    data: {
                      ans: formatSurveyPayload(),
                    },
                    ignoreToken: true,
                  });
                  if (!res.isError) {
                    afterSurveySubmission(res?.data);
                  } else {
                    hideLoader();
                    toast.error('Something went wrong');
                  }
                } else {
                  toast.error('Please provide input');
                }
              } else {
                const currentQuestion = configs[questionNo];

                if (selectedAns[currentQuestion?.id]?.value) {
                  setQuestionNo(questionNo + 1);
                } else {
                  // don't allow user to move to next question
                  toast.error('Please provide input');
                }
              }
            }}
          />
        )}
      </div>
    </div>
  );
};
export default Surveys;
