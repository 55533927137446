import React, { useState, useRef, useEffect } from 'react';
import { toast } from 'react-toastify';
import { connect } from 'twilio-video';

import Video from '../../../../components/Video';
import { ServiceButton } from '../../../../components/Button';
import Dropdown from '../../../../components/Dropdown';
import Text from '../../../../components/Text';

import axios from '../../../../utils/axiosWrapper';
import { API_URLS } from '../../../../utils/constants';
import './index.scss';

const Dashboard = ({ categories, setServices }) => {
  const [room, setRoom] = useState(null);
  const [showJoinCall, setShowJoinCallState] = useState(false);
  const appointmentData = useRef({});
  const enableJoinCall = (data) => {
    if (data.teleconsultation?.call_details?.patient_access_token) setShowJoinCallState(true);
  };
  const getVideoCallStatus = async () => {
    const res = await axios({
      method: 'GET',
      url: API_URLS.videoCallingStatus,
    });
    try {
      if (!res.isError) {
        appointmentData.current = res?.data;
        // check for the first item in list to enable join option
        enableJoinCall(res?.data);
      }
    } catch (err) {
      console.error('err', err);
    }
  };
  useEffect(() => {
    getVideoCallStatus();
  }, []);

  const joinRoom = async ({ patientAccessToken, roomName }) => {
    try {
      const room = await connect(patientAccessToken, {
        name: roomName,
        audio: true,
        video: true,
      });
      setRoom(room);
    } catch (err) {
      console.error('Error', err);
      if (err?.message === 'Permission denied')
        toast.error('Permission denied, please allow audio and video permissions');
      else toast.error('Token invalid');
    }
  };

  const returnToLobby = () => {
    console.log('returnToLobby called');
    setRoom(null);
  };

  // const renderAppointmentList = () => {
  //   return (
  //     <>
  //       {appointmentData.current.teleconsultation.map((appointment) => {
  //         const {
  //           service_name: serviceName,
  //           plan_timestamp: timestamp,
  //           call_details,
  //         } = appointment;
  //         const timestampInMs = Number(timestamp) * 1000;
  //         const date = new Date(timestampInMs);
  //         const day = date.getDate();
  //         const month = date.getMonth();
  //         const year = date.getFullYear();
  //         const hours = date.getHours();
  //         const minutes = date.getMinutes();

  //         return (
  //           <div className='d-flex align-items-center'>
  //             <div className='d-flex flex-column' style={{ marginRight: '24px' }}>
  //               <span style={{ marginBottom: '5px' }}>{serviceName}</span>
  //               <span>
  //                 {day}/{month}/{year} - {hours}:{minutes}
  //               </span>
  //             </div>
  //             <ServiceButton
  //               style={{ maxWidth: '310px', maxHeight: '100px', padding: '15px 18px' }}
  //               divStyle={{ alignItems: 'center' }}
  //               onClick={() =>
  //                 joinRoom({
  //                   patientAccessToken: call_details?.patient_access_token,
  //                   roomName: call_details?.room_name,
  //                 })
  //               }
  //             >
  //               <span>JOIN CALL</span>
  //             </ServiceButton>
  //           </div>
  //         );
  //       })}
  //       <ServiceButton
  //         style={{ margin: '24px' }}
  //         onClick={() => {
  //           returnToLobby();
  //           setShowJoinCallState(false);
  //         }}
  //       >
  //         BOOK APPOINTMENT
  //       </ServiceButton>
  //     </>
  //   );
  // };

  return room === null ? (
    <div className='dashboard-wrapper'>
      {showJoinCall ? (
        <div className='d-flex flex-column align-items-center'>
          <Text className='consultation-text'>
            Your healthcare provider is now available, please click below button to start the
            tele-consultation
          </Text>
          <div className='d-flex align-items-center'>
            <ServiceButton
              style={{ maxWidth: '310px', maxHeight: '100px' }}
              divStyle={{ alignItems: 'center' }}
              onClick={() =>
                joinRoom({
                  patientAccessToken:
                    appointmentData?.current?.teleconsultation?.call_details?.patient_access_token,
                  roomName: appointmentData?.current?.teleconsultation?.call_details?.room_name,
                })
              }
            >
              <span>JOIN CALL</span>
            </ServiceButton>
          </div>
          <span style={{ fontSize: '16px', marginTop: '14px' }}>or</span>
          <ServiceButton
            style={{ margin: '24px' }}
            onClick={() => {
              returnToLobby();
              setShowJoinCallState(false);
            }}
          >
            BOOK APPOINTMENT
          </ServiceButton>
          {/* {renderAppointmentList()} */}
        </div>
      ) : (
        <>
          <div className='category-heading'>How can we help you today ?</div>

          <div>
            <Dropdown
              placeholder='Choose category'
              options={categories.map((value) => ({ value, label: value.name }))}
              onChange={({ value }) => {
                setServices(value.service);
              }}
            />
          </div>
        </>
      )}
    </div>
  ) : (
    <Video room={room} returnToLobby={returnToLobby} />
  );
};

export default Dashboard;
