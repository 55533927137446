import { useState } from 'react';
import DatePicker from 'react-datepicker';

import Input from '../../../../components/Input';
import Dropdown from '../../../../components/Dropdown';
import Button from '../../../../components/Button';
import Text from '../../../../components/Text';

import {
  getItemFromLocalStorage,
  isFormValid,
  updateFormdata,
  validateForm,
} from '../../../../utils/helpers';
import axios from '../../../../utils/axiosWrapper';
import { API_URLS } from '../../../../utils/constants';

const InsuranceForm = ({
  addInsurance,
  showLoader,
  hideLoader,
  skipInsurance,
  insuranceInfo,
  handleInsuranceInfoChange,
  setInsuranceInfo,
  onScanCard,
}) => {
  const handleAddInsurance = async () => {
    const {
      policyHolder,
      firstname,
      lastname,
      gender,
      birthdate,
      relationship,
      insuranceType,
      ...restFields
    } = insuranceInfo;
    const validatePersonalInfo = policyHolder.value === 'no';

    let validationOutput = validateForm({
      ...restFields,
      ...(validatePersonalInfo && {
        firstname,
        lastname,
      }),
    });

    let validForm = isFormValid(validationOutput);
    if (validatePersonalInfo)
      validForm =
        validForm &&
        gender.value !== '' &&
        relationship.value !== '' &&
        birthdate.formattedDate !== '';
    if (validForm || insuranceType.value === '2') {
      showLoader();
      const res = await axios({
        method: 'POST',
        url: API_URLS.insuranceApi,
        data: {
          insurance_options: insuranceInfo.insuranceType.value,
          account: getItemFromLocalStorage('accountId'),
          insurance_provider_name:
            insuranceType.value === '2' ? 'Uninsured' : insuranceInfo.insuranceProvider.value,
          member_id: insuranceInfo.membershipId.value,
          group_number: insuranceInfo.groupId.value,
          is_policy_holder: policyHolder.value === 'yes',
          ...(validatePersonalInfo && {
            policy_holder_first_name: insuranceInfo.firstname.value,
            policy_holder_last_name: insuranceInfo.lastname.value,
            policy_holder_birthdate: insuranceInfo.birthdate.formattedDate,
            policy_holder_gender: insuranceInfo.gender.value || '0',
            policy_holder_relationship: insuranceInfo.relationship.value,
          }),
        },
      });
      if (!res.isError) {
        addInsurance(res);
      }
      hideLoader();
    } else {
      setInsuranceInfo({
        ...updateFormdata(insuranceInfo, validationOutput),
        gender: {
          ...gender,
          error: gender.value === '',
        },
        relationship: {
          ...relationship,
          error: relationship.value === '',
        },
      });
    }
  };
  const displayPersonForm = () => (
    <div>
      <div className='d-flex insurance-field-group'>
        <Input
          state={insuranceInfo.firstname}
          placeholder={insuranceInfo.firstname.placeholder}
          style={{ marginRight: '24px' }}
          onChange={handleInsuranceInfoChange}
          errorMessage={'Provide enter first name'}
        />
        <Input
          state={insuranceInfo.lastname}
          placeholder={insuranceInfo.lastname.placeholder}
          onChange={handleInsuranceInfoChange}
          errorMessage={'Provide enter last name'}
          className='insurance-lastname-wrapper'
        />
      </div>
      <div className='d-flex insurance-field-group'>
        <div style={{ marginRight: '24px' }}>
          <DatePicker
            selected={insuranceInfo.birthdate.value}
            placeholderText={insuranceInfo.birthdate.placeholder}
            dateFormat='yyyy-MM-dd'
            onChange={(date) => {
              const dateObj = new Date(date);
              const formattedDate = `${dateObj.getFullYear()}-${
                dateObj.getMonth() + 1
              }-${dateObj.getDate()}`;

              setInsuranceInfo({
                ...insuranceInfo,
                birthdate: {
                  value: date,
                  error: false,
                  formattedDate: formattedDate,
                },
              });
            }}
            showMonthDropdown
            showYearDropdown
            dropdownMode='select'
          />
        </div>

        <Dropdown
          placeholder={insuranceInfo.gender.placeholder}
          options={[
            { value: 0, label: 'Male' },
            { value: 1, label: 'Female' },
            { value: 2, label: 'Others' },
          ]}
          onChange={({ value }) =>
            setInsuranceInfo({
              ...insuranceInfo,
              gender: {
                ...insuranceInfo.gender,
                value,
                error: false,
              },
            })
          }
          showError={insuranceInfo?.gender.error}
          errorMessage='Provide select gender'
          className='insurance-gender-wrapper'
        />
      </div>
      <div className='d-flex insurance-field-group'>
        <Dropdown
          placeholder={insuranceInfo.relationship.placeholder}
          options={[
            { value: '0', label: 'Father' },
            { value: '1', label: 'Mother' },
            { value: '2', label: 'Spouse' },
            { value: '3', label: 'Brother' },
            { value: '4', label: 'Sister' },
            { value: '5', label: 'Others' },
          ]}
          onChange={({ value }) =>
            setInsuranceInfo({
              ...insuranceInfo,
              relationship: {
                ...insuranceInfo.relationship,
                value,
                error: false,
              },
            })
          }
          showError={insuranceInfo?.relationship.error}
          errorMessage='Provide select relationship'
        />
      </div>
    </div>
  );
  return (
    <div className='d-flex flex-column m-t-24'>
      <Text>Enter your insurance details</Text>
      <div className='d-flex flex-column insurance-field-wrapper'>
        <div className='d-flex insurance-field-group'>
          <Dropdown
            placeholder={insuranceInfo.insuranceType.placeholder}
            options={[
              { value: '0', label: 'Insurance' },
              { value: '1', label: 'Medicare' },
              { value: '2', label: 'Uninsured' },
            ]}
            onChange={({ value }) =>
              setInsuranceInfo({
                ...insuranceInfo,
                insuranceType: {
                  ...insuranceInfo.insuranceType,
                  value,
                  error: false,
                },
              })
            }
            showError={insuranceInfo?.insuranceType.error}
            errorMessage='Provide select insurance type'
          />
        </div>
        {insuranceInfo.insuranceType.value <= 1 && (
          <>
            <div className='d-flex insurance-field-group provider-plan-info'>
              <Input
                state={insuranceInfo.insuranceProvider}
                placeholder={insuranceInfo.insuranceProvider.placeholder}
                style={{ marginRight: '24px' }}
                onChange={handleInsuranceInfoChange}
                errorMessage={'Provide valid insurance provider'}
              />
              <div className='d-flex flex-column flex-1 insurance-policy-holder-wrapper'>
                <Dropdown
                  placeholder='Are you a policy holder'
                  {...(insuranceInfo.policyHolder && {
                    defaultValue: !insuranceInfo.policyHolder.value
                      ? null
                      : insuranceInfo.policyHolder.value === 'yes'
                      ? { value: 'yes', label: 'Yes' }
                      : { value: 'no', label: 'No' },
                  })}
                  options={[
                    { value: 'yes', label: 'Yes' },
                    { value: 'no', label: 'No' },
                  ]}
                  onChange={({ value }) => {
                    setInsuranceInfo({
                      ...insuranceInfo,
                      policyHolder: { value },
                    });
                  }}
                />
                {!insuranceInfo?.policyHolder && (
                  <span style={{ color: 'red', fontSize: '14px' }}>Provide select choice</span>
                )}
              </div>
            </div>
            <div className='d-flex flex-column'>
              <Input
                state={insuranceInfo.membershipId}
                placeholder={insuranceInfo.membershipId.placeholder}
                style={{ marginTop: '24px' }}
                onChange={handleInsuranceInfoChange}
                errorMessage={'Provide enter membership Id'}
              />
              <Input
                state={insuranceInfo.groupId}
                placeholder={insuranceInfo.groupId.placeholder}
                style={{ marginTop: '24px' }}
                onChange={handleInsuranceInfoChange}
                errorMessage={'Provide enter group Id'}
              />
            </div>
            {insuranceInfo?.policyHolder.value === 'no' && displayPersonForm()}
          </>
        )}
      </div>

      <div>
        <div
          style={{
            margin: '32px 0',
            justifyContent: 'center',
          }}
          className='m-t-24 d-flex flex-column align-items-center cursor-pointer'
        >
          <Button style={{ width: '190px' }} onClick={handleAddInsurance}>
            Next
          </Button>
          <Button
            style={{ margin: '24px 0', padding: '12px', width: '190px' }}
            onClick={onScanCard}
          >
            Scan Insurance Card
          </Button>
        </div>
      </div>
    </div>
  );
};

export default InsuranceForm;
